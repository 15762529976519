/* .animation-pulse {
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.8);
    box-shadow: 0 0 0 0 rgba(37, 211, 102, 1);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 25px transparent;
  }

  100% {
    transform: scale(0.8);
  }
} */

.tri-right.btm-right-alpha:after {
  content: " ";
  position: absolute;
  width: 15px;
  height: 15px;
  left: 95%;
  top: -8px;
  background: white;
  border: 2px solid transparent;
  /* border-left-color: #d0d3d6c6;
  border-top-color: #d0d3d6c6; */
  transform: translate(-50%) rotate(45deg);
}

@media screen and (max-width: 767px) {
  .tri-right.btm-right-alpha:after {
    left: 85%;
    top: -8px;
  }
}
