
.react-datetime-picker {
    background-color: #ffffff;
    width: 100%;
    border-radius: 0.375rem;
  }
  
  
  
  .react-datetime-picker__inputGroup {
    background-color: #ffffff; 
  padding-left: 2px;
  padding-right: 2px;
    padding-top: 5px;
    padding-bottom: 5px;
    width:100%;
  } 

  .react-datetime-picker:focus,
.react-datetime-picker.react-datetime-picker--focused {
  border-color: #3498db; /* Change this color to your desired border color on focus */
  outline: none; /* Remove the default outline */
}