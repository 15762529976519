.custom-scrollbar::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #c8cbd0;
  border-radius: 6px;
  border: 3px solid #ffffff;
}

.custom-scrollbar::-webkit-scrollbar-track {
  border-radius: 6px;
}
