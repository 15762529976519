@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.25;
  }
  100% {
    opacity: 1;
  }
}

.animate-blink {
  animation: blink 2s infinite;
}