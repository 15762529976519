.sidebar {
    background: linear-gradient(180deg, #fbfaff 0%, #f3e8ff 50%, #f0e6ff 100%);
  }

  .links-container::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  
  .links-container::-webkit-scrollbar-thumb {
    background-color: #c8cbd0;
    border-radius: 6px;
    /* border: 3px solid #ffffff; */
  }
  
  .links-container::-webkit-scrollbar-track {
    border-radius: 6px;
  }
  