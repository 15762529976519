.react-datepicker {
  transform: translateX(
    -40px
  );
}
.react-datepicker__day--selected {
    background-color: #3b82f6;
}

.react-datepicker__time-list-item--selected {
    background-color: #3b82f6 !important;

}
